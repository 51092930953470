import React, { useEffect, useState, useCallback } from "react";
import "firebase/firestore";
import moment from "moment";
import { ClarityIcons, pencilIcon, trashIcon } from "@clr/core/icon";
import { CdsSearch } from "@clr/react/search";
import debounce from "lodash.debounce";
import "@clr/core/icon/register.js";

import { UserDialog } from "./user-dialog";
import { DeleteConfirmation } from "../../components/modals/delete-confirmation";
import { getAll, removeUser } from "./users.service";
import { updateTitle } from "../../utils/browser-api";
import { Loading } from "../../components/loading/loading";
import "./users.css";

ClarityIcons.addIcons(pencilIcon);
ClarityIcons.addIcons(trashIcon);

export const Users = () => {
  const [filterUsers, setFilterUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userAction, setUserAction] = useState(null);
  const [filterVal, setFilterVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const delayedQuery = useCallback(
    debounce((q) => filter(q), 1000),
    [users]
  );
  const [sortDirection, setSortDirection] = useState("asc");

  updateTitle("Quiz Em - Users");

  const closeDialog = async (user) => {
    if (!user) {
      setSelectedUser(null);
      return;
    }
    const _ = users.filter((u) => u.id !== user.id);
    setUsers([..._, user]);
    setFilterUsers([..._, user]);
    setFilterVal("");
    setSelectedUser(null);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setFilterVal(value);
    delayedQuery(value);
  };

  const filter = (val) => {
    setFilterVal(val);
    if (val) {
      const _ = users.filter((q) => {
        if (
          q.name?.toLowerCase().includes(val.toLowerCase()) ||
          q.email?.toLowerCase().includes(val.toLowerCase()) ||
          q.memberid?.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
      setFilterUsers(_);
    } else {
      setFilterUsers(users);
    }
  };

  const sort = (column) => {
    const isAsc = sortDirection === "asc" ? false : true;
    if (sortDirection === "asc") {
      setSortDirection("desc");
    } else {
      setSortDirection("asc");
    }
    const _users = [...filterUsers].sort((a, b) => {
      if (isAsc) {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    setFilterUsers(_users);
  };

  const createUser = () => {
    setSelectedUser({
      id: null,
      name: "",
      email: "",
      admin: false,
      memberid: "",
      expiration: moment().add("1", "years").format("YYYY-MM-DD"),
    });
    setUserAction("create");
  };

  const editUser = (user) => {
    setSelectedUser(user);
    setUserAction("edit");
  };

  const deleteUser = (user) => {
    setSelectedUser(user);
    setUserAction("delete");
  };

  const onDelete = async (willDelete) => {
    const _selectedUser = selectedUser;
    setSelectedUser(null);
    setUserAction(null);
    setError(false);

    if (!willDelete) {
      return;
    } else {
      setLoading(true);
      const res = await removeUser(_selectedUser);
      if (!res) {
        setError(true);
        setLoading(false);
      } else {
        const _ = users.filter((u) => u.id !== _selectedUser.id);
        setUsers(_);
        setFilterUsers(_);
        setFilterVal("");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const _users = await getAll();
      setUsers(_users);
      setFilterUsers(_users);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <div className="top-row">
        <div className="top-row-left">
          <cds-button size="sm" onClick={createUser}>
            Add User
          </cds-button>
          <CdsSearch control-width="shrink">
            <label>filter</label>
            <input type="search" onChange={handleChange} value={filterVal} />
          </CdsSearch>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="w75"></th>
            <th className="left pointer" onClick={() => sort("name")}>
              NAME
            </th>
            <th className="left pointer" onClick={() => sort("email")}>
              EMAIL
            </th>
            <th className="left pointer" onClick={() => sort("expiration")}>
              ACCESS EXPIRATION
            </th>
            <th className="left pointer" onClick={() => sort("memberid")}>
              MEMBER ID
            </th>
            <th className="left pointer" onClick={() => sort("totalattempts")}>
              TOTAL ATTEMPTS
            </th>
            <th className="left pointer" onClick={() => sort("highestscore")}>
              HIGHEST SCORE
            </th>
            <th className="left pointer" onClick={() => sort("lowestscore")}>
              LOWEST SCORE
            </th>
            <th className="left pointer" onClick={() => sort("averagescore")}>
              AVERAGE SCORE
            </th>
          </tr>
        </thead>
        <tbody>
          {filterUsers?.map((u) => {
            return (
              <tr key={u.id}>
                <td className="action-column">
                  <cds-icon
                    shape="pencil"
                    onClick={() => editUser(u)}
                  ></cds-icon>
                  <cds-icon
                    shape="trash"
                    onClick={() => deleteUser(u)}
                  ></cds-icon>
                </td>
                <td className="left">{u.name}</td>
                <td className="left">{u.email}</td>
                <td className="left">{moment(u.expiration).format("LL")}</td>
                <td className="left">{u.memberid}</td>
                <td className="left">{u.totalattempts}</td>
                <td className="left">{u.highestscore}</td>
                <td className="left">{u.lowestscore}</td>
                <td className="left">{u.averagescore}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {selectedUser !== null &&
        (userAction === "edit" || userAction === "create") && (
          <UserDialog user={selectedUser} close={closeDialog} />
        )}
      {selectedUser !== null && userAction === "delete" && (
        <DeleteConfirmation
          title="Delete Account"
          body={`Are you sure you want to delete ${selectedUser.name}? This data will be permanantly removed from our servers forever. This action cannot be undone.`}
          close={onDelete}
        />
      )}
      {loading && <Loading />}
    </>
  );
};
