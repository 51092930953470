import React from "react";
import { CdsModal, CdsModalActions, CdsModalContent, CdsModalHeader } from '@clr/react/modal';
import { CdsButton } from '@clr/react/button';

export const DeleteConfirmation = ({ close, title, body }) => {
    return (
        <CdsModal closable={false} onCloseChange={close}>
            <CdsModalHeader>
                <h3 cds-text="title">{title}</h3>
            </CdsModalHeader>
            <CdsModalContent>
                <p cds-text="body">{body}</p>
            </CdsModalContent>
            <CdsModalActions>
                <CdsButton action="outline" onClick={() => close(false)}>Cancel</CdsButton>
                <CdsButton onClick={() => close(true)} status="danger">Delete</CdsButton>
            </CdsModalActions>
        </CdsModal>
    )
}