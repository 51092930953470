import React, { useEffect, useState } from "react";
import moment from "moment";

import { updateTitle } from "../../utils/browser-api";
import { getAllFeedback } from "./feedback.service";
import { Loading } from "../../components/loading/loading";
import "./feedback.css";

export const Feedback = () => {
  const [loading, setLoading] = useState([]);
  const [feedback, setFeedback] = useState([]);

  updateTitle("Quiz Em - Feedback");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const _feedback = await getAllFeedback();
      setFeedback(_feedback || []);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th className="left w175">DATE</th>
            <th className="left">RATING</th>
            <th className="left w150">NAME</th>
            <th className="left">DETAILS</th>
            <th className="left">PERMISSION TO USE</th>
            <th className="left">EMAIL</th>
            <th className="left">MEMBER ID</th>
          </tr>
        </thead>
        <tbody>
          {feedback?.map((q) => {
            return (
              <tr key={q.id}>
                <td className="left">
                  {moment(q.createdat * 1000).format("lll")}
                </td>
                <td className="left">{q.rating}</td>
                <td className="left">{q.name}</td>
                <td className="left">{q.details}</td>
                <td className="left">{q.cancontact ? "YES" : "NO"}</td>
                <td className="left">{q.email}</td>
                <td className="left">{q.memberid}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {loading && <Loading />}
    </>
  );
};
