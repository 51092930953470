import Axios from "axios";

import { headerObj } from "../../utils/http";

export const getOrgSettings = async () => {
  const header = await headerObj();
  const { data } = await Axios.get(
    `${process.env.REACT_APP_REST_PRODUCTION_URL}/settings`,
    header
  );
  return data;
};
