import React, { useEffect, useState, useRef } from "react";
import { CdsIcon } from "@clr/react/icon";
import { ClarityIcons, infoCircleIcon } from "@clr/core/icon";

import "./signpost.css";

ClarityIcons.addIcons(infoCircleIcon);

export const SignPost = ({sections, questions}) => {
  const [show, setShow] = useState(false);
  const [sectionTotals, setSectionTotals] = useState([]);
  const ref = useRef(null);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });


  useEffect(() => {
    if(sections && questions) {
      const _ = sections.map((section) => {
        const count = questions.filter(q => q.section === section.name).length;
        return {
          section: section.name,
          count
        }
      });
      setSectionTotals(_);
    }

  }, [sections, questions]);

  return (
    <div style={{position: 'static'}}>
      <CdsIcon
        shape="infoCircleIcon"
        size="sm"
        onClick={(e) => {
            setShow(s => !s);
        }}
      ></CdsIcon>
      {show && (
        <div className="sp-content" ref={ref}>
          <div className="sp-wrapper">
            <div className="sp-pointer"></div>
            <div className="sp-header">Total Questions {questions.length}</div>
            <div className="sp-body">
              {sectionTotals.map(s => <p style={{marginTop: 10}} className="p4" key={s.section}><b>({s.count})</b> {s.section}</p>)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
