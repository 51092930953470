import React, { useEffect, useState } from "react";
import {getOrgSettings} from "./settings.service";

import { Loading } from "../../components/loading/loading";
import './settings.css';

export const Settings = () => {
    const [settings, setSettings] = useState({
        defaultExpiration: 0,
        defaultQuestionCount: 0
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      (async () => {
        setLoading(true);
        const _settings = getOrgSettings();
        // TODO: remove [0] once DB is updated to remove old columns
        setSettings(_settings[0]);
        setLoading(false);
      })();
    }, []);

    return (
        <>
        <h2>Settings</h2>

        <h4>User Access</h4>
        {/* <div style={{
            marginTop: 20,
            width: 300
        }}>
        <CdsInput layout="compact">
          <label>Default Expiration</label>
          <input type="number" placeholder=""
            value={settings?.defaultExpiration}
            onChange={(e) =>
              setSettings({
                ...settings,
                defaultExpiration: parseInt(e.target.value),
              })
            }
          />
          <cds-control-message>Days</cds-control-message>
        </CdsInput>
        <div style={{marginTop: 20}}>&nbsp;</div>
        <CdsInput layout="compact">
          <label>Question Count</label>
          <input type="number" placeholder=""
            value={settings?.defaultQuestionCount}
            onChange={(e) =>
              setSettings({
                ...settings,
                defaultQuestionCount: parseInt(e.target.value),
              })
            }
          />
          <cds-control-message>Amount of questions per quiz</cds-control-message>
        </CdsInput>
        </div> */}
        {loading &&     <Loading />}
        </>
    )
}