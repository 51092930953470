import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "firebase/auth";
import * as firebase from "firebase/app";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import "firebase/firestore";
import 'normalize.css/normalize.css';
import '@clr/core/global.css'; // pre-minified version breaks
import '@clr/city/css/bundles/default.min.css';

import "./App.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const firebaseConfig = {
  apiKey: "AIzaSyC898qozxcY3zwB9_loGrfcomcg1L1Jxts",
  authDomain: "quizem-1f475.firebaseapp.com",
  databaseURL: "https://quizem-1f475.firebaseio.com",
  projectId: "quizem-1f475",
  storageBucket: "quizem-1f475.appspot.com",
  messagingSenderId: "1028272424014",
  appId: "1:1028272424014:web:11fa438c157a0382eaaf1f",
  measurementId: "G-DYKGK5LJ1K",
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </FirebaseAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
