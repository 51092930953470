import Axios from "axios";

import { headerObj } from "../../utils/http";

export const getSections = async () => {
  const header = await headerObj();
  const { data } = await Axios.get(
    `${process.env.REACT_APP_REST_PRODUCTION_URL}/sections`,
    header
  );
  return data;
};

export const getQuestions = async () => {
  const header = await headerObj();
  const { data } = await Axios.get(
    `${process.env.REACT_APP_REST_PRODUCTION_URL}/questionBank`,
    header
  );
  return data;
};

export const deleteQuestion = async (question) => {
  const header = await headerObj();
  await Axios.delete(
    `${process.env.REACT_APP_REST_PRODUCTION_URL}/questionBank/${question.id}`,
    header
  );
  return "true";
};

export const createQuestion = async (question) => {
  const header = await headerObj();
  const { data } = await Axios.post(
    `${process.env.REACT_APP_REST_PRODUCTION_URL}/questionBank`,
    question,
    header
  );
  return data;
};

export const updateQuestion = async (question) => {
  const header = await headerObj();
  const { data } = await Axios.put(
    `${process.env.REACT_APP_REST_PRODUCTION_URL}/questionBank/${question.id}`,
    question,
    header
  );
  return data;
};
