import React, { useState } from "react";
import { CdsModal, CdsModalActions, CdsModalContent, CdsModalHeader } from '@clr/react/modal';
import { CdsButton } from '@clr/react/button';
import { CdsInput } from '@clr/react/input';
import { CdsCheckboxGroup, CdsCheckbox } from '@clr/react/checkbox';
import moment from "moment";
import { addUser, updateUser } from "./users.service";

export const UserDialog = ({ close, user }) => {
  const [selectedUser, setSelectedUser] = useState({
    ...user,
    expiration: moment(user.expiration).format("YYYY-MM-DD"),
  });

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const emailRegex = RegExp(
    "^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,7}$"
  );

  const create = async () => {
    setError(false);
    setLoading(true);
    const res = await addUser(selectedUser);
    if (!res) {
      setError(true);
      setLoading(false);
    } else {
      console.log(res)
      close({...selectedUser, id: res});
    }
  }

  const update = async () => {
    setError(false);
    setLoading(true);
    const res = await updateUser({ ...selectedUser, ...{ expiration: moment(selectedUser.expiration).format() } });
    if (!res) {
      setError(true);
      setLoading(false);
    } else {
      close(selectedUser);
    }
  }

  return (
    <CdsModal closable={true} onCloseChange={() => close(null)}>
      <CdsModalHeader>
        <h3 cds-text="title">{!user.id ? 'New User' : 'Update User'}</h3>
      </CdsModalHeader>
      <CdsModalContent>
        {!error && <p cds-text="body">This information will be displayed to the user.</p>}
        {error && <p cds-text="body" style={{ color: 'red' }}>An error occurred. Please try again.</p>}
        <CdsInput layout="vertical">
          <label>Full Name</label>
          <input placeholder=""
            value={selectedUser.name}
            onChange={(e) =>
              setSelectedUser({
                ...selectedUser,
                name: e.target.value,
              })
            }
          />
        </CdsInput>
        <CdsInput layout="vertical">
          <label>Email</label>
          <input type="email" placeholder=""
            value={selectedUser.email}
            disabled={selectedUser.id}
            onChange={(e) =>
              setSelectedUser({
                ...selectedUser,
                email: e.target.value.toLowerCase(),
              })
            }
          />
        </CdsInput>
        <CdsInput layout="vertical">
          <label>Access Expiration</label>
          <input type="date" placeholder=""
            value={selectedUser.expiration}
            onChange={(e) =>
              setSelectedUser({
                ...selectedUser,
                expiration: e.target.value.toLowerCase(),
              })
            }
          />
        </CdsInput>
        <CdsInput layout="vertical">
          <label>Member Id</label>
          <input placeholder=""
            value={selectedUser.memberid}
            onChange={(e) =>
              setSelectedUser({
                ...selectedUser,
                memberid: e.target.value.toLowerCase(),
              })
            }
          />
        </CdsInput>

        <CdsCheckboxGroup layout="vertical">
          <label>Access Level</label>
          <CdsCheckbox>
            <label>Admin</label>
            <input type="checkbox"
              onChange={() =>
                setSelectedUser({
                  ...selectedUser,
                  admin: !selectedUser.admin,
                })
              }
              checked={selectedUser.admin}
            />
          </CdsCheckbox>
        </CdsCheckboxGroup>
      </CdsModalContent>
      <CdsModalActions>
        <CdsButton action="outline" onClick={() => close(null)}>Cancel</CdsButton>
        {!selectedUser.id ?
          <CdsButton onClick={create} loading-state={loading ? "loading" : "default"}>Create</CdsButton>
          : <CdsButton onClick={update} loading-state={loading ? "loading" : "default"}>Update</CdsButton>}
      </CdsModalActions>
    </CdsModal>
  );
};
