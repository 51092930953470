import React, { useEffect, useState } from "react";

import { getMetrics } from "./dashboard.service";
import { updateTitle } from "../../utils/browser-api";
import { Loading } from "../../components/loading/loading";

import "./dashboard.css";

export const Dashboard = () => {
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(false);

  updateTitle("Quiz Em - Dashboard");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const _metrics = await getMetrics();
      setMetrics(_metrics[0]);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <div className="card-container">
        <div className="card">
          <div className="card-header">TOTAL USERS</div>
          <div className="card-block">
            <div className="card-text">{metrics?.totalusers}</div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">TOTAL ATTEMPTS</div>
          <div className="card-block">
            <div className="card-text">{metrics?.totalquizzes}</div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">AVERAGE SCORE</div>
          <div className="card-block">
            <div className="card-text">{metrics?.averagescore}</div>
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </>
  );
};
