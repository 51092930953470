import React, { useState } from "react";
import firebase from "firebase/app";
import { CdsButton } from "@clr/react/button";
import { CdsInput } from "@clr/react/input";

export const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const login = async () => {
    setError(null);
    await firebase.auth().signInWithEmailAndPassword(email, password).catch(err => setError(err.message));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div>
        <h2>Sign into your account</h2>
      </div>
      <div>
        <h6 style={{
          color: 'red'
        }}>
          {error}
        </h6>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          height: 250,
          width: 400,
        }}
      >
        <CdsInput layout="vertical">
          <label>Email address</label>
          <input
            type="email"
            placeholder=""
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="nope"
          />
        </CdsInput>
        <CdsInput layout="vertical">
          <label>Password</label>
          <input
            type="password"
            placeholder=""
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="nope"
          />
        </CdsInput>
        <CdsButton
          action="outline"
          onClick={login}
          style={{
            marginTop: 10,
          }}
        >
          Sign In
        </CdsButton>
      </div>
    </div>
  );
};
