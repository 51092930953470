import * as firebase from "firebase/app";

const authToken = () => {
    return firebase.auth().currentUser.getIdToken();
}


export const headerObj = async () => {
    const token = await authToken();
    return {
        headers: {
            "Content-Type": "application/json",
            authToken: token,
        },
    }
}