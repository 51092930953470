import React from "react";
import { Route, Switch, NavLink, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { auth } from "firebase/app";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import { CdsIcon } from "@clr/react/icon";
import { ClarityIcons, logoutIcon } from "@clr/core/icon";

import { Login } from "./components/login";
import { Dashboard } from "./views/dashboard/dashboard";
import { Questions } from "./views/questions/questions";
import { Users } from "./views/users/users";
import { Feedback } from "./views/feedback/feedback";
import "./App.css";
import { Loading } from "./components/loading/loading";
import { Settings } from "./views/settings/settings";

ClarityIcons.addIcons(logoutIcon)

function App({ history }) {

  return (
    <FirebaseAuthConsumer>
      {({ isSignedIn, user, providerId }) => {
        if (providerId === null) {
          return <Loading />
        }
        if (user) {
          return (
            <div className="main-container">
              {/* <div className="alert alert-app-level">
 dd
    </div> */}
              <header className="header header-6">
                <div className="branding">
                  <a href="..." className="nav-link">
                    <span className="title">QUIZ EM</span>
                  </a>
                </div>
                <CdsIcon shape="logout" size="md" onClick={() => auth().signOut()} className="logout"></CdsIcon>
              </header>
              <nav className="subnav">
                <ul className="nav">
                  <li className="nav-item">
                    <NavLink
                      to="/dashboard"
                      activeClassName="active"
                      className="nav-link"
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/users"
                      activeClassName="active"
                      className="nav-link"
                    >
                      Users
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/questions"
                      activeClassName="active"
                      className="nav-link"
                    >
                      Questions
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/feedback"
                      activeClassName="active"
                      className="nav-link"
                    >
                      Feedback
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/settings"
                      activeClassName="active"
                      className="nav-link"
                    >
                      Settings
                    </NavLink>
                  </li>
                </ul>
              </nav>
              <div className="content-container">
                <div className="content-area">
                  <Switch>
                    <Route exact path="/questions" component={Questions} />
                    <Route exact path="/users" component={Users} />
                    <Route exact path="/feedback" component={Feedback} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/settings" component={Settings} />
                    <Redirect to="/dashboard" />
                  </Switch>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <Switch>
              <Route exact path="/login" component={Login} />
              <Redirect to="/login" />
            </Switch>
          );
        }
      }}
    </FirebaseAuthConsumer>
  );
}

export default withRouter(App);
