import React, { useEffect, useState, useCallback } from "react";
import "firebase/firestore";
import debounce from "lodash.debounce";
import { ClarityIcons, pencilIcon, trashIcon, helpIcon } from "@clr/core/icon";
import { CdsSearch } from "@clr/react/search";

import { getSections, getQuestions, deleteQuestion } from "./question.service";

import { QuestionDialog } from "./question-dialog";
import { updateTitle } from "../../utils/browser-api";
import { DeleteConfirmation } from "../../components/modals/delete-confirmation";
import { Loading } from "../../components/loading/loading";
import { SignPost } from "../../components/signpost/signpost";
import "./questions.css";

ClarityIcons.addIcons(pencilIcon);
ClarityIcons.addIcons(trashIcon);
ClarityIcons.addIcons(helpIcon);

export const Questions = () => {
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [filterVal, setFilterVal] = useState("");
  const delayedQuery = useCallback(
    debounce((q) => filterQuestions(q), 1000),
    [questions]
  );

  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questionAction, setQuestionAction] = useState(null);

  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);

  updateTitle("Quiz Em - Questions");

  const onDelete = async (willDelete) => {
    const _selectedQuestion = selectedQuestion;
    setSelectedQuestion(null);
    setQuestionAction(null);

    if (!willDelete) {
      setSelectedQuestion(null);
      setQuestionAction(null);
      return;
    } else {
      setLoading(true);
      const res = await deleteQuestion(_selectedQuestion);
      setLoading(false);
      if (!res) {
        console.error(res);
      } else {
        const _questions = questions.filter(
          (q) => q.id !== _selectedQuestion.id
        );
        setQuestions(_questions);
        setFilteredQuestions(_questions);
      }
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setFilterVal(value);
    delayedQuery(value);
  };

  const filterQuestions = (filterBy) => {
    const _ = questions.filter((q) => {
      if (
        q.title.toLowerCase().includes(filterBy) ||
        q.section.toLowerCase().includes(filterBy) ||
        q.correctanswer.toLowerCase().includes(filterBy)
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredQuestions(_);
  };

  const closeQuestionDialog = (question) => {
    if (question) {
      const qs = questions.filter((q) => q.id !== question.id);
      setQuestions([...qs, question]);
      setFilteredQuestions([...qs, question]);
      setFilterVal("");
    }
    setSelectedQuestion(null);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const responses = await Promise.all([getSections(), getQuestions()]);
      const _sections = responses[0];
      const _questions = responses[1];
      setQuestions(_questions);
      setFilteredQuestions(_questions);
      setSections(_sections || []);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <div className="top-row">
        <div className="top-row-left">
          <cds-button
            size="sm"
            onClick={() => {
              setSelectedQuestion({ id: null });
              setQuestionAction("create");
            }}
          >
            Add Question
          </cds-button>
          <CdsSearch control-width="shrink">
            <label>filter</label>
            <input type="search" onChange={handleChange} value={filterVal} />
          </CdsSearch>
        </div>
        <div className="top-row-right">
          <h6 className="post-label">
            Total Questions ({questions.length})
          </h6>
          <SignPost sections={sections} questions={questions} />
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th className="w75"></th>
            <th className="left">QUESTION</th>
            <th className="left">SECTION</th>
            <th className="left w95">CORRECT %</th>
          </tr>
        </thead>
        <tbody>
          {filteredQuestions?.map((q) => {
            return (
              <tr key={q.id}>
                <td className="action-column">
                  <cds-icon
                    shape="pencil"
                    onClick={() => {
                      setSelectedQuestion(q);
                      setQuestionAction("edit");
                    }}
                  ></cds-icon>
                  <cds-icon
                    shape="trash"
                    onClick={() => {
                      setSelectedQuestion(q);
                      setQuestionAction("delete");
                    }}
                  ></cds-icon>
                </td>
                <td className="left">{q.title}</td>
                <td className="left">{q.section}</td>
                <td className="left">{q.correctpercent}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {selectedQuestion !== null &&
        (questionAction === "edit" || questionAction === "create") && (
          <QuestionDialog
            question={selectedQuestion}
            close={closeQuestionDialog}
            sections={sections}
          />
        )}
      {selectedQuestion !== null && questionAction === "delete" && (
        <DeleteConfirmation
          title="Delete Question"
          body="Are you sure you want to delete this question? Users will still have access to this in any previous attempts. This action cannot be undone."
          close={onDelete}
        />
      )}
      {loading && <Loading />}
    </>
  );
};
