import Axios from "axios";

import { headerObj } from "../../utils/http";

export const getAll = async () => {
  const header = await headerObj();
  const { data } = await Axios.get(
    `${process.env.REACT_APP_REST_PRODUCTION_URL}/users`,
    header
  );
  return data;
};

export const removeUser = async (user) => {
  const header = await headerObj();
  const { data } = await Axios.delete(
    `${process.env.REACT_APP_REST_PRODUCTION_URL}/users/${user.id}`,
    header
  );
  return "true";
};

export const updateUser = async (user) => {
  const header = await headerObj();
  const { data } = await Axios.put(
    `${process.env.REACT_APP_REST_PRODUCTION_URL}/users/${user.id}`,
    user,
    header
  );
  return data;
};

export const addUser = async (user) => {
  const header = await headerObj();
  const { data } = await Axios.post(
    `${process.env.REACT_APP_REST_PRODUCTION_URL}/users`,
    user,
    header
  );
  return data;
};
