import React, { useState } from "react";
import {
  CdsModal,
  CdsModalActions,
  CdsModalContent,
  CdsModalHeader,
} from "@clr/react/modal";
import { CdsButton } from "@clr/react/button";
import { CdsSelect } from "@clr/react/select";
import { CdsFormGroup } from "@clr/react/forms";
import { CdsIcon } from "@clr/react/icon";
import { ClarityIcons, plusCircleIcon } from "@clr/core/icon";

import {createQuestion, updateQuestion} from './question.service';

ClarityIcons.addIcons(plusCircleIcon);

export const QuestionDialog = ({ question, close, sections }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(
    question.id
      ? question
      : {
          id: null,
          title: "",
          section: "",
          correctanswer: "",
          note: "",
          options: ["", ""],
        }
  );
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const create = async () => {
    const q = {
      ...selectedQuestion,
      title: selectedQuestion.title.trim(),
      correctanswer: selectedQuestion.correctanswer.trim(),
      section: selectedQuestion.section.trim(),
      note: selectedQuestion.note.trim(),
      options: selectedQuestion.options.map((o) => o.trim()),
    };
    const res = await createQuestion(q).catch(err => setError(err.toString()));
    if(res) {
      close({...q, id: res});
    }
  };

  const update = async () => {
    const q = {
      ...selectedQuestion,
      title: selectedQuestion.title.trim(),
      correctanswer: selectedQuestion.correctanswer.trim(),
      section: selectedQuestion.section.trim(),
      note: selectedQuestion.note.trim(),
      options: selectedQuestion.options.map((o) => o.trim()),
    };
    const res = await updateQuestion(q).catch(err => setError(err.toString()));
    if(res) {
      close({...q, id: res});
    }
  };

  const isFormValid = () => {
    if(
      selectedQuestion?.title?.length === 0 ||
      selectedQuestion?.section?.length === 0 ||
      selectedQuestion?.correctanswer?.length === 0 ||
      selectedQuestion?.options?.length <= 1 ||
      !selectedQuestion?.options?.includes(selectedQuestion.correctanswer)
      ) {
        return false;
      } else {
        return true;
      }
  }

  return (
    <CdsModal closable={true} onCloseChange={() => close(null)} size="md">
      <CdsModalHeader>
        <h3 cds-text="title">
          {!question.id ? "New Question" : "Edit Question"}
        </h3>
      </CdsModalHeader>
      <CdsModalContent>
        {error !== null && (
          <p cds-text="body" style={{ color: "red" }}>
            {error}
          </p>
        )}
        <CdsFormGroup layout="horizontal">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              height: "100%",
              width: "100%",
              fontSize: 13
            }}
          >
            <span style={{ fontSize: 13, fontWeight: 700 }}>Question</span>
            <div style={{ width: "85%", minHeight: 60 }}>
              <textarea
                style={{ lineHeight: "normal", width: "100%", height: "100%" }}
                value={selectedQuestion.title}
                onChange={(e) =>
                  setSelectedQuestion({
                    ...selectedQuestion,
                    title: e.target.value,
                  })
                }
              ></textarea>
            </div>
          </div>
          <CdsSelect>
            <label>Section</label>
            <select
            value={selectedQuestion.section}
              onChange={(e) =>
                setSelectedQuestion({
                  ...selectedQuestion,
                  section: e.target.value,
                })
              }
            >
              <option></option>
              {sections.map((section) => {
                return (
                  <option key={section.id} value={section.name}>
                    {section.name}
                  </option>
                );
              })}
            </select>
          </CdsSelect>
          <label>
            <span style={{ marginRight: 10, fontSize: 13, fontWeight: 700 }}>
              Options
            </span>
            <CdsIcon
              shape="plus-circle"
              onClick={() => {
                setSelectedQuestion({
                  ...selectedQuestion,
                  options: [...selectedQuestion.options, ""],
                });
              }}
            ></CdsIcon>
          </label>
          {selectedQuestion.options.map((option, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center",
                  height: "100%",
                  width: "100%",
                  fontSize: 13
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    fontSize: 13
                  }}
                >
                  <CdsIcon
                    shape="trash"
                    onClick={() => {
                      setSelectedQuestion({
                        ...selectedQuestion,
                        options: selectedQuestion.options.filter(
                          (opt) => opt !== option
                        ),
                      });
                    }}
                  ></CdsIcon>
                </div>
                <div style={{ width: "85%", minHeight: 60 }}>
                  <textarea
                    style={{
                      lineHeight: "normal",
                      width: "100%",
                      height: "100%",
                    }}
                    value={option}
                    onChange={(e) =>
                      setSelectedQuestion({
                        ...selectedQuestion,
                        options: selectedQuestion.options.map((opt, i) => {
                          if (index === i) {
                            return e.target.value;
                          } else {
                            return opt;
                          }
                        }),
                      })
                    }
                  ></textarea>
                </div>
              </div>
            );
          })}
          <CdsSelect>
            <label>Correct Answer</label>
            <select
              value={selectedQuestion.correctanswer}
              onChange={(e) =>
                setSelectedQuestion({
                  ...selectedQuestion,
                  correctanswer: e.target.value,
                })
              }
            >
              <option></option>
              {selectedQuestion.options.map((opt, index) => {
                return <option key={index}>{opt}</option>;
              })}
            </select>
          </CdsSelect>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              height: "100%",
              width: "100%",
              fontSize: 13
            }}
          >
            <span style={{ fontSize: 13, fontWeight: 700 }}>Note</span>
            <div style={{ width: "85%", minHeight: 60 }}>
              <textarea
                style={{ lineHeight: "normal", width: "100%", height: "100%" }}
                value={selectedQuestion.note}
                onChange={(e) =>
                  setSelectedQuestion({
                    ...selectedQuestion,
                    note: e.target.value,
                  })
                }
              ></textarea>
            </div>
          </div>
        </CdsFormGroup>
      </CdsModalContent>
      <CdsModalActions>
        <CdsButton action="outline" onClick={() => close(null)}>
          Cancel
        </CdsButton>
        {!selectedQuestion.id ? (
          <CdsButton
            onClick={create}
            loading-state={loading ? "loading" : "default"}
            disabled={!isFormValid()}
          >
            Create
          </CdsButton>
        ) : (
          <CdsButton
            onClick={update}
            loading-state={loading ? "loading" : "default"}
            disabled={!isFormValid()}
          >
            Update
          </CdsButton>
        )}
      </CdsModalActions>
    </CdsModal>
    // <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
    //   <div className="fixed inset-0 transition-opacity">
    //     <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
    //   </div>
    //   <div className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all xl:w-6/12 sm:w-8/12">
    //     <form style={{ maxHeight: 600, overflow: "scroll" }}>
    //       <div>
    //         <div>
    //           <div>
    //             <h3 className="text-lg leading-6 font-medium text-gray-900">
    //               {selectedQuestion.id ? "New Question" : "Edit Question"}
    //             </h3>
    //           </div>
    //         </div>
    //         <div className="mt-6 sm:mt-5">
    //           <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
    //             <label
    //               htmlFor="question"
    //               className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
    //             >
    //               Question
    //             </label>
    //             <div className="mt-1 sm:mt-0 sm:col-span-2">
    //               <div className="max-w-lg rounded-md shadow-sm">
    //                 <textarea
    //                   id="question"
    //                   className="form-input block w-full sm:text-sm sm:leading-5"
    //                   placeholder=""
    //                   value={selectedQuestion.title}
    //                   onChange={(e) =>
    //                     setSelectedQuestion({
    //                       ...selectedQuestion,
    //                       title: e.target.value,
    //                     })
    //                   }
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //           <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
    //             <label
    //               htmlFor="section"
    //               className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
    //             >
    //               Section
    //             </label>
    //             <div className="mt-1 sm:mt-0 sm:col-span-2">
    //               <div className="max-w-lg rounded-md shadow-sm">
    //                 <select
    //                   value={selectedQuestion.section}
    //                   onChange={(e) =>
    //                     setSelectedQuestion({
    //                       ...selectedQuestion,
    //                       section: e.target.value,
    //                     })
    //                   }
    //                   aria-label="CorrectAnswer"
    //                   id="section"
    //                   className="form-select relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
    //                 >
    //                   <option></option>
    //                   {sections.map((sect) => {
    //                     return <option key={sect.id}>{sect.name}</option>;
    //                   })}
    //                 </select>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
    //             <label
    //               htmlFor="options"
    //               className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
    //             >
    //               Options
    //               <svg
    //                 fill="currentColor"
    //                 viewBox="0 0 20 20"
    //                 height="24"
    //                 width="24"
    //                 style={{
    //                   display: "inline-block",
    //                   marginLeft: 5,
    //                   cursor: "pointer",
    //                 }}
    //                 onClick={() => {
    //                   setSelectedQuestion({
    //                     ...selectedQuestion,
    //                     options: [...selectedQuestion.options, ""],
    //                   });
    //                 }}
    //               >
    //                 <path
    //                   d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
    //                   clipRule="evenodd"
    //                   fillRule="evenodd"
    //                 ></path>
    //               </svg>
    //             </label>
    //             <div className="mt-1 sm:mt-0 sm:col-span-2">
    //               {selectedQuestion.options.map((o, index) => {
    //                 return (
    //                   <div
    //                     className="max-w-lg rounded-md shadow-sm"
    //                     style={{ marginTop: 10 }}
    //                     key={index}
    //                   >
    //                     <textarea
    //                       id={index + 1}
    //                       className="form-input block w-11/12 sm:text-sm sm:leading-5"
    //                       style={{ display: "inline-block" }}
    //                       placeholder=""
    //                       value={o}
    //                       onChange={(e) =>
    //                         setSelectedQuestion({
    //                           ...selectedQuestion,
    //                           options: selectedQuestion.options.map(
    //                             (opt, i) => {
    //                               if (index === i) {
    //                                 return e.target.value;
    //                               } else {
    //                                 return opt;
    //                               }
    //                             }
    //                           ),
    //                         })
    //                       }
    //                     />
    //                     <div
    //                       style={{
    //                         display: "inline-block",
    //                         marginBottom: "1em",
    //                         marginLeft: 5,
    //                         cursor: "pointer",
    //                         color: "#E02325",
    //                       }}
    //                     >
    //                       <svg
    //                         fill="currentColor"
    //                         viewBox="0 0 20 20"
    //                         height="24"
    //                         width="24"
    //                         onClick={() => {
    //                           setSelectedQuestion({
    //                             ...selectedQuestion,
    //                             options: [
    //                               ...selectedQuestion.options.filter(
    //                                 (_, i) => i !== index
    //                               ),
    //                             ],
    //                           });
    //                         }}
    //                       >
    //                         <path
    //                           d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
    //                           clipRule="evenodd"
    //                           fillRule="evenodd"
    //                         ></path>
    //                       </svg>
    //                     </div>
    //                   </div>
    //                 );
    //               })}
    //             </div>
    //           </div>
    //           <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
    //             <label
    //               htmlFor="correctAnswer"
    //               className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
    //             >
    //               Correct Answer
    //             </label>
    //             <div className="mt-1 sm:mt-0 sm:col-span-2">
    //               <div className="max-w-lg rounded-md shadow-sm">
    //                 <select
    //                   value={selectedQuestion.correctanswer}
    //                   onChange={(e) =>
    //                     setSelectedQuestion({
    //                       ...selectedQuestion,
    //                       correctanswer: e.target.value,
    //                     })
    //                   }
    //                   aria-label="CorrectAnswer"
    //                   id="correctAnswer"
    //                   className="form-select relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
    //                 >
    //                   <option></option>
    //                   {selectedQuestion.options.map((opt, index) => {
    //                     return <option key={index}>{opt}</option>;
    //                   })}
    //                 </select>
    //               </div>
    //             </div>
    //           </div>

    //           <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
    //             <label
    //               htmlFor="note"
    //               className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
    //             >
    //               Note
    //             </label>
    //             <div className="mt-1 sm:mt-0 sm:col-span-2">
    //               <div className="max-w-lg rounded-md shadow-sm">
    //                 <textarea
    //                   id="note"
    //                   className="form-input block w-full sm:text-sm sm:leading-5"
    //                   placeholder=""
    //                   value={selectedQuestion.note}
    //                   onChange={(e) =>
    //                     setSelectedQuestion({
    //                       ...selectedQuestion,
    //                       note: e.target.value,
    //                     })
    //                   }
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="mt-8 border-t border-gray-200 pt-5">
    //         <div className="flex justify-end">
    //           <span className="inline-flex rounded-md shadow-sm">
    //             <button
    //               type="button"
    //               onClick={() => close(null)}
    //               className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
    //             >
    //               Cancel
    //             </button>
    //           </span>
    //           <span className="ml-3 inline-flex rounded-md shadow-sm">
    //             <button
    //               type="button"
    //               onClick={() => close(selectedQuestion)}
    //               disabled={
    //                 selectedQuestion?.title?.length > 0 &&
    //                 selectedQuestion?.section?.length > 0 &&
    //                 selectedQuestion?.options?.length > 1 &&
    //                 selectedQuestion?.correctanswer?.length > 0 &&
    //                 selectedQuestion?.options[0].length > 0 &&
    //                 selectedQuestion?.options[1].length > 0
    //                   ? false
    //                   : true
    //               }
    //               className={`${
    //                 selectedQuestion?.title?.length > 0 &&
    //                 selectedQuestion?.section?.length > 0 &&
    //                 selectedQuestion?.options?.length > 1 &&
    //                 selectedQuestion?.correctanswer?.length > 0 &&
    //                 selectedQuestion?.options[0].length > 0 &&
    //                 selectedQuestion?.options[1].length > 0
    //                   ? ""
    //                   : "opacity-50 cursor-not-allowed"
    //               }
    //                   inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out`}
    //             >
    //               {selectedQuestion.id ? "Update" : "Create"}
    //             </button>
    //           </span>
    //         </div>
    //       </div>
    //     </form>
    //   </div>
    // </div>
  );
};
