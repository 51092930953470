import React from "react";
import "./loading.css";

export const Loading = () => {
  return (
    <div
      className="loading-container"
    >
      <span className="spinner spinner-lg"></span>
    </div>
  );
};
